<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="5" v-if="$Can('portfolio_add_btn')">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" router-link to="/portfolio/portfolio-add">
                  <span class="text-nowrap">Add Portfolio</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listPortfolios"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          :fields="customerHeaders"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :per-page="perPage"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <strong class="text-primary">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
          </template>
          <!-- Column: title -->
          <template #cell(title)="data">
            {{ data.item.title ? data.item.title : 'N/A' }}
          </template>

          <!-- Column: team -->
          <template #cell(team)="data">
            <b-badge v-if="data.item.team == 'Excel'" pill variant="success" class="badge-glow">Excel</b-badge>
            <b-badge v-if="data.item.team == 'Web'" pill variant="primary" class="badge-glow">Web</b-badge>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge v-if="data.item.status == 'A'" pill variant="success" class="badge-glow">Published</b-badge>
            <b-badge v-if="data.item.status == 'B'" pill variant="danger" class="badge-glow">Unpublished</b-badge>
          </template>

          <!-- <template #cell(sellerType)="data">
            <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
            <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" v-if="$Can('portfolio_edit_btn')">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'portfolio-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import portfolioStoreModule from '../portfolioStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {
    vSelect,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
  },

  setup() {
    const PORTFOLIO_APP_STORE_MODULE_NAME = 'portfolio';

    // Register module
    if (!store.hasModule(PORTFOLIO_APP_STORE_MODULE_NAME)) store.registerModule(PORTFOLIO_APP_STORE_MODULE_NAME, portfolioStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PORTFOLIO_APP_STORE_MODULE_NAME)) store.unregisterModule(PORTFOLIO_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      where: router.currentRoute.name,
      filter: null,
      perPage: localStorage.getItem('customerShow') ?? 10,
      fetchCustomers: [],
      listPortfolios: [],
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      excelJson: {},
      baseURL: store.state.app.baseURL,
      loading: false,

      customerHeaders: [
        {
          key: 'id',
          label: 'ID',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'title',
          label: 'PROJECT NAME',
          class: 'text-center',
          thStyle: 'width: 50%',
        },

        {
          key: 'team',
          label: 'TEAM',
          class: 'text-center',
          thStyle: 'width: 15%',
        },

        {
          key: 'status',
          label: 'STATUS',
          class: 'text-center',
          thStyle: 'width: 15%',
        },

        {
          key: 'actions',
          label: '#',
          class: 'text-center',
          thStyle: 'width: 5%',
        },
      ],

      status: null,
      sortBy: 'id',
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('customerShow', val);

        this.listRefresh();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.listRefresh();
      },
    },

    filter: _.debounce(function(newVal) {
      this.listRefresh();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.listRefresh();
        }
      },
    },
  },

  methods: {
    onRowSelected(item) {
      if(this.$Can('portfolio_edit_btn')){
        router.push({ name: 'portfolio-edit', params: { id: item[0].id } });

      }
    },

    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD-MM-YYYY HH:mm');
      }
    },

    listRefresh() {
      this.getItems('fetchPortfolios');
    },
    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    getItems(val) {
      this.loading = true;
      store
        .dispatch('portfolio/' + val, {
          perPage: this.perPage,

          page: this.currentPage,
          filter: this.filter,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        })
        .then((res) => {
          this.listPortfolios = res.data.data.data;
          this.totalRows = res.data.data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.listRefresh();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
