import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPortfolios(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('showAllPortfolio', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getPortfolioById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showPortfolio/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    savePortfolio(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePortfolio', data)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updatePortfolio(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updatePortfolio/${data.id}`, data)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    deletePortfolio(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deletePortfolio', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
